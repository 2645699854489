<!-- <section id="education">
  <div class="education-container">
    <div class="container fade-in">
      <h1>Education</h1>
      <p class="education-description fade-in">
        With over a decade of professional experience in the field, I am a
        committed Mental Health Professional specializing in the treatment of
        adolescents and adults facing diverse emotional and behavioral
        challenges.
        My passion lies in facilitating growth and healing, empowering
        individuals, and couples to navigate life's complexities with resilience
        and insight.<br />
        <br>Throughout my career, I have cultivated a client-centred approach,
        working closely with clients to understand their motives with compassion and
        respect. Fluent in both English and Spanish, I am adept at bridging
        cultural barriers to provide comprehensive care. <br>
        <br>I aim to create a safe environment where clients can introspectively
        examine themselves through a lens of compassion, thereby activating the
        inherent capacity for agency within each individual. <br>
      </p>
      <br>
      <br>
      <h3 class="fade-in">EDUCATION</h3>
      <ul class="fade-in">
        <li>
          2021 – 2022 Mental Health Professional: Mental Health BREM Program -
          North York, Ontario
        </li>
        <li>2000 – 2005 Bachelor in Humanities: Major in Psychology Pontifical
          Catholic University of Peru - Lima, Peru</li>
        <li>WES Canadian Equivalency: Bachelor, Major in Psychology</li>
      </ul>
      <br>
      <h3 class="fade-in">TRAINING AND CERTIFICATIONS</h3>
      <ul class="fade-in">
        <li>2024 Compassionate Inquiry</li>
        <li>2023 Emotionally Focused Therapy Core Skills, Carolina EFT
          Centre</li>
        <li>2022 Emotionally Focused Therapy Externship, Carolina EFT
          Centre</li>
        <li>2022 Paul Gilbert Introduces Compassion Focused Therapy,
          Professional
          Training Workshop Leading Edge Seminars</li>
        <li>2009 Professional Degree in Psychology: Major in Clinical Psychology
          <br> Pontificial Catholic University of Peru - Lima, Peru (75 hours
          certificate)</li>
      </ul>
    </div>
    <img src="assets/education.jpg" alt="education-photo" class="education-photo fade-in">
  </div>
</section> -->


<section id="education">
  <div class="education-container">
    <div class="container fade-in">
      <br>
      <br>
      <h1>{{ 'EDUCATION.TITLE' | translate }}</h1>
      <br>
      <p class="education-description fade-in">
        {{ 'EDUCATION.DESCRIPTION' | translate }}<br />
        <br>{{ 'EDUCATION.APPROACH' | translate }}<br>
        <br>{{ 'EDUCATION.ENVIRONMENT' | translate }}<br>
      </p>
      <br>
      <br>
      <h3 class="fade-in">{{ 'EDUCATION.EDUCATION_HEADING' | translate }}</h3>
      <ul class="fade-in">
        <li>
          {{ 'EDUCATION.EDUCATION_BREM' | translate }}
        </li>
        <li>{{ 'EDUCATION.EDUCATION_PUCP' | translate }}</li>
      </ul>
      <br>
      <h3 class="fade-in">{{ 'EDUCATION.TRAINING_HEADING' | translate }}</h3>
      <ul class="fade-in">
        <li>{{ 'EDUCATION.COMPASSIONATE' | translate }}</li>
        <li>{{ 'EDUCATION.CORE' | translate }}</li>
        <li>{{ 'EDUCATION.EXTERNSHIP' | translate }}</li>
        <li>{{ 'EDUCATION.GILBERT' | translate }}</li>
        <li>{{ 'EDUCATION.PROFDEGREE' | translate }}
          <br>{{ 'EDUCATION.PUCP' | translate }}</li>
      </ul>
    </div>
    <img src="assets/education.jpg" alt="education-photo" class="education-photo fade-in">
  </div>
</section>
