<!-- <section id="home">
  <div class="about-container">
    <img src="assets/about.jpg" alt="About Photo" class="about-photo">
    <div class="container">
      <p class="about-description">
       <br> Hi, <br />
        <br />My name is Stefany Rosemberg. <br />
        <br />I was born and raised in Lima, Peru, in a culturally rich
        environment shaped by my father&#39;s Peruvian
        heritage and my mother&#39;s Swiss background. As the youngest of five
        siblings, I learned to navigate a diverse
        family dynamic, which has deeply influenced my understanding and
        appreciation of different perspectives. <br />
        <br />My passion for psychology began in my teenage years, leading me to
        pursue it as a career. After graduating
        from university as a Clinical Psychologist, I provided counseling to young
        mothers in at-risk situations and
        later worked as a school psychologist for several years. <br />
        <br />Ten years ago, I moved to Canada, a country for which I am
        profoundly grateful. Here, I welcomed my third
        daughter and have been raising my family while continuing to pursue my
        professional aspirations. <br />
        <br />After dedicating seven years to being a full-time mom, I reignited
        my career by completing the BREM
        program. This pivotal step allowed me to restart my professional journey.
        <br />
        <br />Today, I am a Registered Psychotherapist in Ontario, and a proud
        member of CRPO. I am immensely grateful
        for the opportunity to collaborate with my clients on their own journeys,
        supporting them with warmth and
        professionalism. <br />
        <br />I look forward to working with you. <br />
        <br />Warm regards, <br />Stefany <br />
        <br />Stefany Rosemberg, RP # 12019
      </p>
    </div>
  </div>
</section> -->

<section id="home">
  <div class="about-container">
    <img src="assets/about.jpg" alt="About Photo" class="about-photo">
    <div class="container">
      <p class="about-description">
       <br> {{ 'ABOUT.INTRO' | translate }} <br />
        <br /> {{ 'ABOUT.NAME' | translate }} <br />
        <br /> {{ 'ABOUT.BIO' | translate }}<br />
        <br /> {{ 'ABOUT.PASSION' | translate }}<br />
        <br /> {{ 'ABOUT.MOVE' | translate }} <br />
        <br /> {{ 'ABOUT.CAREER' | translate }}
        <br />
        <br /> {{ 'ABOUT.CURRENT' | translate }} <br />
        <br /> {{ 'ABOUT.SIGN_OFF' | translate }} <br />
        <br />{{ 'ABOUT.REGARDS' | translate }}<br />{{ 'ABOUT.NAME2' | translate }} <br />
        <br />{{ 'ABOUT.FULLNAME' | translate }}
      </p>
    </div>
  </div>
</section>