<footer class="footer-container">
  <div class="footer-content">
    <div class="footer-logo">
      <a
        href="https://www.psychologytoday.com/ca/therapists/stefany-rosemberg-hope-psychotherapy-burlington-on/983812">
        <img src="assets/psychtoday.png" alt="Psychology Today Verified Logo">
      </a>

      <a href="https://ca.linkedin.com/in/stefanyrosemberg">
        <img src="assets/linkedin.png" alt="Linkedin Logo">
      </a>
    </div>
    <div class="footer-info">
      <p>{{ 'FOOTER.COPYRIGHT' | translate }}</p>
      <p>1155 North Service Road West Unit 11 - Office 128 <br> Oakville, ON L6M
        3E3 <br> (289) 274-0014</p>
    </div>
  </div>
</footer>

<!--
<footer class="footer-container">
  <div class="footer-content">
    <div class="footer-logo">
      <img src="assets/psychtoday.png" alt="Psychology Today Verified Logo">
    </div>
     <div class="footer-links">
      <a href="mailto:email@example.com" class="footer-icon">
        <mat-icon>email</mat-icon> 
      </a>
      <a href="tel:+1234567890" class="footer-icon">
        <mat-icon>phone</mat-icon>
      </a>
      <a href="https://goo.gl/maps/example" target="_blank" class="footer-icon">
        <mat-icon>location_on</mat-icon> Oakville, ON
      </a>
    </div>
    <p>&copy; Stefany Rosemberg, RP # 12019</p>
  </div>
</footer> -->