<!-- <div class="steps-wrapper">
  <h2 class="steps-title">Therapy Process</h2>

  <div class="step-container">
    <div class="rectangle">
      <h3>Step 1</h3>
      <p>15-minutes free phone call consultation to discuss your reasons to attend therapy, my therapeutic approach, fees and process.</p>
    </div>

    <div class="rectangle">
      <h3>Step 2</h3>
      <p>This first session is dedicated to exploring your concerns and to set expectations and goals for your process.</p>
    </div>

    <div class="rectangle">
      <h3>Step 3</h3>
      <p>Our sessions are designed to focus on the goals and expectations we established together during your initial intake session. Understanding that this is a client-centered approach, these goals can be reviewed and redefined at any point throughout the process.</p>
    </div>
  </div>
  <br>
  <br>
</div> -->

<div class="steps-wrapper">
  <h2 class="steps-title">Therapy Process</h2>

  <div class="step-container">
    <div class="rectangle">
      <h3>{{ 'STEPS.STEP1T' | translate }}</h3>
      <p>{{ 'STEPS.STEP1D' | translate }}</p>
    </div>

    <div class="rectangle">
      <h3>{{ 'STEPS.STEP2T' | translate }}</h3>
      <p>{{ 'STEPS.STEP2D' | translate }}</p>
    </div>

    <div class="rectangle">
      <h3>{{ 'STEPS.STEP3T' | translate }}</h3>
      <p>{{ 'STEPS.STEP3D' | translate }}</p>
    </div>
  </div>
  <br>
  <br>
</div>
