<!-- <section id="services">
  <div class="contact-container fade-in">
    <img src="assets/contact.jpeg" alt="Contact Photo" class="contact-photo fade-in">
    <div class="container fade-in">
      <h1>Services</h1>
      <div class="container-description">
        <ul>
          <li>Available both in-person and online.</li>
          <li>Client Focus: Individuals and Couples</li>
          <li>Age: Teenagers, Adults</li>
          <li>Languages: English, Spanish</li>
        </ul>
        <br>
        <div class="button-container">
          <button mat-flat-button id="btn" (click)="sendEmail()">Contact</button>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section id="services">
  <div class="contact-container fade-in">
    <img src="assets/contact.jpeg" alt="Contact Photo" class="contact-photo fade-in">
    <div class="container fade-in">
      <h1>{{ 'CONTACT.TITLE' | translate }}</h1>
      <div class="container-description">
        <ul>
          <li>{{ 'CONTACT.AVAILABLE' | translate }}</li>
          <li>{{ 'CONTACT.FOCUS' | translate }}</li>
          <li>{{ 'CONTACT.AGE' | translate }}</li>
          <li>{{ 'CONTACT.LANGUAGES' | translate }}</li>
        </ul>
        <br>
        <div class="button-container">
          <button mat-flat-button id="btn" (click)="bookSession()">{{ 'CONTACT.CONTACT_BUTTON' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</section>