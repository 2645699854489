import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  bookSession(){
    window.location.href='https://stefanyrosemberg.janeapp.com';
  }
  currentLanguage: string;
  constructor(private translate: TranslateService) {
    this.currentLanguage = 'en';
    translate.setDefaultLang(this.currentLanguage);
  }

  switchLanguage() {
    if (this.currentLanguage === 'en') {
      this.currentLanguage = 'es';
    } else {
      this.currentLanguage = 'en';
    }
    this.translate.use(this.currentLanguage);
  }

  get toggleButtonLabel(): string {
    return this.currentLanguage === 'en' ? 'ES' : 'EN';
  }
}
