<!-- <nav class="navbar">
  <img src="assets/logoRemoved.png" alt="SR-Logo" class="logo">
  <div class="nav-links">
    <ul class="links">
      <li><a href="#home">Home</a></li>
      <li><a href="#education">Education</a></li>
      <li><a href="#services">Services</a></li>
    </ul>
    <button mat-flat-button id="btn" (click)="sendEmail()">Contact</button>
  </div>
</nav>
<div class="divider"></div> -->

<nav class="navbar">
  <img src="assets/logoRemoved.png" alt="SR-Logo" class="logo">
  <p id="quote">{{ 'HEADER.QUOTE' | translate }}</p>
  <div class="nav-links">
    <ul class="links">
      <li><a href="#home">{{ 'HEADER.HOME' | translate }}</a></li>
      <li><a href="#education">{{ 'HEADER.EDUCATION' | translate }}</a></li>
      <li><a href="#services">{{ 'HEADER.SERVICES' | translate }}</a></li>
    </ul>
    <button mat-flat-button id="btn" (click)="bookSession()">{{ 'HEADER.CONTACT' | translate }}</button>
    <button mat-flat-button id="lang-btn" (click)="switchLanguage()">
      {{ currentLanguage === 'en' ? 'ES' : 'EN' }}
    </button>
  </div>
</nav>
<div class="divider"></div>
